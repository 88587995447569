<template>
    <div class="flag-container">
        <div class="flex-bt">
            <a href="#/mix_add">
                <el-button slot="title" type="primary" size="small" round>
                    创建合集
                </el-button>
            </a>
            <span class="sort-btn" v-if="list.length" @click="setSort()"><i class="el-icon-sort"></i> {{sInfo.edit?'完成':'设置排序【拖曳修改】'}}</span>
        </div>
        <div ref="mix" class="" style="margin-top: 15px;position: relative;">
            <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
            <div v-else>
                <!-- <div class="flag-list" :class="{'mv':mInfo.mi==index}" @touchstart="touchLi($event, index, m);" @touchmove="touchLi($event, index, m, 1);" @touchend="touchLi($event, index, m, 2);" @dragstart="dragMove($event, index, m);" @drag="dragMove($event, index, m, 1)" @dragend="dragMove($event, index, m, 2);" draggable="true" v-for="(m, index) in list"> -->
                <div class="flag-list" :class="{'mv':mInfo.mi==index}" @dragstart="dragMove($event, index, m);" @drag="dragMove($event, index, m, 1)" @dragend="dragMove($event, index, m, 2);" draggable="true" v-for="(m, index) in list">
                    <a href="javascript:;">
                        <el-skeleton :loading="loading" animated :count="1">
                            <template slot="template">
                                <el-skeleton-item variant="image" class="img" />
                                <div class="content">
                                    <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                                    <el-skeleton-item variant="text" style="width: 50%;" />
                                </div>
                                <div class="bottom flex-bt">
                                    <el-skeleton-item variant="text" style="width: 30%;" />
                                    <el-skeleton-item variant="text" style="width: 30%;" />
                                </div>
                            </template>
                            <template>
                                <div class="flag-box" @click.stop="showDrawer(m.id)">
                                    <div class="img-box">
                                        <el-image class="img" :src="rImg(m.cover)" fit="cover">
                                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                        </el-image>
                                    </div>
                                    <div class="content">
                                        <div>
                                            <div class="title-box">
                                                <div class="title ellipsis-2">{{m.title }}</div>
                                                <span class="time">{{m.create_time}}</span>
                                            </div>
                                            <!-- <span class="tag" v-if="m.shenhe==-1 && m.reason">原因：{{m.reason}}</span>
                                            <div class="tag-box">
                                                <span class="tag check" v-if="m.shenhe!=1">{{m.shenhe==0? '审核中':'未通过'}}</span>
                                            </div> -->
                                            <p class="dy-num">{{ m.count }}个作品</p>
                                        </div>
                                        <div class="bottom flex-bt">
                                            <div class="num-box flex">
                                                <div class="icon-box">
                                                    <i class="el-icon-view"></i>
                                                    <span class="see">{{ returnNum(m.visit) }}</span>
                                                </div>
                                                <div class="icon-box">
                                                    <i class="iconfont icon-jushoucang"></i>
                                                    <span class="see">{{ returnNum(m.lover) }}</span>
                                                </div>
                                            </div>
                                            <div class="op-box" @click.stop>
                                                <span class="op" @click="del(m, index)">删除</span>

                                                <router-link class="op" :to="{path:'/mix_add', query:{id:m.id}}">修改</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-skeleton>
                    </a>
                    <div class=""></div>
                </div>
                <div class="flag-list move" ref="move" v-show="mInfo.mi!=-1">
                    <a href="javascript:;">
                        <div class="flag-box">
                            <div class="img-box">
                                <el-image class="img" :src="rImg(sInfo.val.cover)" fit="cover">
                                    <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                            </div>
                            <div class="content">
                                <div>
                                    <div class="title-box">
                                        <div class="title ellipsis-2">{{sInfo.val.title }}</div>
                                        <span class="time">{{sInfo.val.create_time}}</span>
                                    </div>
                                    <p class="dy-num">{{ sInfo.val.count }}个作品</p>
                                </div>
                                <div class="bottom flex-bt">
                                    <div class="num-box flex">
                                        <div class="icon-box">
                                            <i class="el-icon-view"></i>
                                            <span class="see">{{ returnNum(sInfo.val.visit) }}</span>
                                        </div>
                                        <div class="icon-box">
                                            <i class="iconfont icon-jushoucang"></i>
                                            <span class="see">{{ returnNum(sInfo.val.lover) }}</span>
                                        </div>
                                        <div class="icon-box set-box">
                                            <span class="see">操作</span>
                                        </div>
                                    </div>
                                    <div class="op-box">
                                        <span class="op">删除</span>
                                        <span class="op">修改</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <el-drawer title="合集详情" :visible.sync="drawer.show" :direction="direction">
            <div class="drawer-box" @scroll="scrollToBottom($event)">
                <mydyList :list="showList" :min="1"></mydyList>
                <p class="more-btn" v-show="list.length&&list.length%20==0" @click="getShowList()">点击获取更多</p>
            </div>
        </el-drawer>
    </div>

</template>

<script>
    import mydyList from "@components/common/mydy-list";
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {
            mydyList
        },
        data() {
            return {
                loading: false,
                myid: '',
                list: [],
                showList: [],
                drawer: {
                    show: false,
                    id: 0
                },
                sInfo: {
                    x1: 0, // offset x
                    y1: 0, // offset y
                    top: 0, // 父元素距离顶部的距离 用以计算是第几个li
                    h: 0, // 
                    touch: 0,
                    val: {
                        masterimg: []
                    },
                    edit: 0
                },
                mInfo: {
                    x2: 0, // 当前x
                    y2: 0, // 当前y
                    mi: -1,
                    num: 0
                },
                direction: 'rtl',
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.myid = this.$y_getKey('userid');
            this.getData();
            this.sInfo.top = this.$refs.mix.offsetTop;
            this.sInfo.left = this.$refs.mix.offsetLeft;
            if (window.innerWidth < 600) {
                this.direction = 'btt'
            }
        },
        methods: {
            // 设置排序
            async setSort() {
                if (!this.sInfo.edit) {
                    this.sInfo.edit = 1;
                } else {
                    let arr = [];
                    this.list.forEach(v => {
                        arr.push(v.id)
                    })
                    this.$y_loading();
                    let ret = await this.$y_ajax('api/Dynamic.Mix/sort', {
                        mix_ids: arr.join(',')
                    })
                    this.$y_loadingClose();
                    this.$y_msg(ret.msg);
                    this.sInfo.edit = 0;
                }
            },
            // 移动端
            touchLi(event, i, m, type) {
                let _this = this;
                if (!_this.sInfo.edit) return;
                _this.sInfo.touch = 1;
                let x = event.changedTouches[0].pageX;
                let y = event.changedTouches[0].pageY;
                let e = event.currentTarget;
                if (!type) {
                    _this.sInfo.x1 = x - e.offsetLeft - _this.mInfo.left;
                    _this.sInfo.y1 = y - e.offsetTop - _this.mInfo.top;
                    this.moveM(x, y, e, m, i)
                } else {
                    this.moveM2(x, y, type)
                }
            },
            dragMove(event, i, m, type) {
                let _this = this;
                if (!_this.sInfo.edit || _this.sInfo.touch) return;
                let x = event.clientX;
                let y = event.clientY;
                let e = event.currentTarget;
                if (!type) {
                    _this.sInfo.x1 = event.offsetX;
                    _this.sInfo.y1 = event.offsetY;
                    this.moveM(x, y, e, m, i)
                } else {
                    this.moveM2(x, y, type)
                }
            },
            // pc 开始
            mouseLi(event, i, m) {
                let _this = this;
                if (!_this.sInfo.edit) return;
                if (_this.sInfo.touch) return;
                let x = event.clientX;
                let y = event.clientY;
                let e = event.currentTarget;
                _this.sInfo.x1 = event.offsetX;
                _this.sInfo.y1 = event.offsetY;
                this.moveM(x, y, e, m, i)
            },
            // pc 移动&结束
            mouseLi2(event, type) {
                let _this = this;
                if (!_this.sInfo.edit) return;
                if (_this.sInfo.touch) return;
                let x = event.clientX;
                let y = event.clientY;
                this.moveM2(x, y, type);
            },
            // 开始
            moveM(x, y, e, m, i) {

                let _this = this;
                let $move = _this.$refs.move;

                $move.style.width = e.offsetWidth + 'px';
                _this.sInfo.h = e.offsetHeight + 10;

                _this.sInfo.val = m;
                _this.mInfo.mi = i;

                _this.mInfo.x2 = x;
                _this.mInfo.y2 = y;
                $move.style.top = (y + 10) + 'px'; // 避免鼠标与虚假li重合
                $move.style.left = (x - _this.sInfo.x1) + 'px';
                // $move.style.top = (y - _this.sInfo.y1) + 'px';
            },
            // 移动及结束
            moveM2(x, y, type) {
                let _this = this;
                let $move = _this.$refs.move;
                let num = Math.floor((_this.mInfo.y2 + window.scrollY - _this.sInfo.top) / _this.sInfo.h);
                if (num < 0) {
                    num = 0;
                } else if (num >= _this.list.length) {
                    num = _this.list.length - 1;
                }
                if (type == 1) {
                    _this.mInfo.num = num;
                } else {
                    num = _this.mInfo.num
                }
                _this.rmList(_this.mInfo.mi, num);
                _this.mInfo.mi = num;
                _this.mInfo.x2 = x;
                _this.mInfo.y2 = y;
                $move.style.top = (y + 10) + 'px'; // 避免鼠标与虚假li重合
                $move.style.left = (x - _this.sInfo.x1) + 'px';
                // $move.style.top = (y - _this.sInfo.y1) + 'px';
                if (type == 2) {
                    _this.mInfo.mi = -1;
                }
            },
            // 重排位置
            rmList(s, e) {
                var _this = this;
                var b = _this.list.splice(s, 1);
                _this.list.splice(e, 0, b[0]);
            },
            // 显示合集作品弹窗
            async showDrawer(id) {
                if (this.sInfo.edit) return;
                this.drawer.show = true;
                this.drawer.id = id;
                this.getShowList(1)
            },
            scrollToBottom(event) {
                let e = event.currentTarget;
                if (e.offsetHeight + e.scrollTop >= e.scrollHeight) {
                    this.getShowList();
                }
            },
            // 获取某一合集作品列表
            async getShowList(first) {
                let param = {
                    id: this.drawer.id,
                }
                if (first) {
                    param.page = 1;
                }
                let {
                    list,
                } = await this.$y_list('api/Dynamic.Mix/term', this.showList, param)
                this.showList = list;
            },
            // 获取合集列表
            async getData() {
                let ret = await this.$y_ajax('api/Dynamic.Mix/list');
                if (ret.code == 200) {
                    this.list = ret.data;
                } else {
                    this.$y_msg(ret.msg)
                }

            },
            // 删除合集
            del(m, index) {
                let _this = this;
                if (this.sInfo.edit) return;
                this.$y_confirm({
                    msg: `确定删除【${m.title}】？`
                }, async (bi) => {
                    if (bi == 1) {
                        let ret = await _this.$y_ajax('api/Dynamic.Mix/delete', {
                            id: m.id
                        })
                        _this.$y_msg(ret.msg);
                        if (ret.code == 200) {
                            _this.list.splice(index, 1);
                        }
                    }
                })
            },
        },
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    @import '@assets/less/flag.less';
    @import '@assets/less/mylist.less';

    .drawer-box {
        padding: 0 15px;
        height: 100%;
        overflow-y: auto;
    }

    .dy-num {
        color: #49C265;
        margin-top: 10px;
    }

    .flag-list {
        position: relative;
    }

    .mv {
        opacity: 0;
    }

    .move {
        position: fixed;
        box-shadow: 0 2px 5px 0px #333;
        background: #fff;
        z-index: 1;
        padding: 20px 10px;
        box-sizing: border-box;
    }

    .sort-btn {
        color: #49C265;
        cursor: pointer;
    }

    .more-btn {
        text-align: center;
        line-height: 50px;
        color: #aaa;
        cursor: pointer;
    }

    /deep/.el-drawer__body {
        overflow: hidden;
        overflow-x: auto;
    }

    @media (max-width: 600px) {
        /deep/.el-drawer.btt {
            height: 70% !important;
        }
    }
</style>